<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <img style="padding-left: 40px;" src="../../assets/biofuel.png" alt="">
              <h4>Відновлення пароля</h4>
              <div class="form-group">
                <input class="form-control" autocomplete="false" type="text" required="" placeholder="Email або телефон" formControlName="login" />
              </div>
              <div class="form-group mb-0">
                <!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Passwort vergessen?</a> -->
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" type="button" [routerLink]="['/orders']"><span>Відновити пароль</span></button>
                </div>
              </div>
              <div class="reg"><span>Згадали пароль?</span> <a [routerLink]="['/auth/registration']">Увійти</a></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
