import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { RequestService } from "src/app/services/http-service.service";
import { GeneralService } from '../../general-service.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public show: boolean = false
  public errorMessage: any;

  
  email = '';
  password = '';

  passwordType = 'password'

  constructor(private fb: FormBuilder,
    private general: GeneralService,
    public router: Router,
    public http: RequestService) {
  }

  ngOnInit() { }


  login() {
    
    if (this.email != '' && this.password != '') {
      this.general.post('login', { email: this.email, password: this.password }).then(res => {
        localStorage.setItem('token', res.data.token);
        this.router.navigateByUrl('/orders', { replaceUrl: true });
      }, err => {
        alert('Неправильний email або пароль');
      });
    } else {
      alert('Заповніть усі поля');
    }
  }

  registr() {
    this.router.navigate(["/auth/registration"]);
  }

  showPassword() {
    this.show = !this.show
  }
}
